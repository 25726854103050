"use client";
import { CenterBox, Text } from "@/components/styled";

import NotFoundAnimation from "@/public/assets/lottie-animations/search-not-found.json";
import { useLottiePlayer } from "@/hooks/useLottiePlayer";

export const NotFound: React.FC = () => {
  const DynamicLottiePlayer = useLottiePlayer();
  return (
    <CenterBox width={"100%"} height={"100%"}>
      <CenterBox mt="xl">
        <Text color="primary" size={18}>
          No Results Found
        </Text>
        <DynamicLottiePlayer
          autoplay
          loop
          style={{
            height: "30%",

            backgroundRepeat: "no-repeat",
          }}
          src={NotFoundAnimation}
        ></DynamicLottiePlayer>
      </CenterBox>
    </CenterBox>
  );
};
